.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }

  
/* 
  @media screen and (max-width: 768px) {
    .hr-line {
      width: 100%;
      height: 4px;
      background-color: #ddd;
      margin: 15px 0;
      display: flex;
      position: relative;
    }
  }
  
  @media screen and (max-width: 480px) {
    .hr-line {
      width: 100%;
      height: 4px;
      background-color: #ddd;
      margin: 15px 0;
      display: flex;
      position: relative;
    }
  } */

   
.hr-line {
  width: 100%;
  height: 5px;
  background-color: #ddd;
  margin: 20px 0;
  display: flex;
  position: relative;
}


.hr-line::before,
.hr-line::after {
  content: '';
  height: 100%;
  transition: width 0.3s ease-in-out; 
}

.hr-line::after {
  width:25%;
  background-color: #bd0324;
}
.hr-line::before{
  background-color: #ddd;
}

.hr-line.theme::before {
  width: 0%; 
}

.hr-line.product::before {
  width: 25%; 
}

.hr-line.geosust::before {
  width: 50%; 
}

.hr-line.geocoast::before{
  width: 75%;
}


  .item-buttons1{
    display: flex;
    justify-content: center;
    margin-left: 50px; 
  }
  .tab-link-icon-product{

    height: 50px;
    width: 90px; 
  }

  @media screen and (max-width: 768px) {
    .item-buttons1{
      display: flex;
    }
  }
  
  @media screen and (max-width: 480px) {
    .item-buttons1{
      
      display: flex;
      
    }
  }

  .item-button {
    cursor: pointer;
    padding: 10px 20px;
    margin: 0 10px;
    font-size: 16px;
    color: #333;
    transition: color 0.3s ease-in-out;
  }
  
  .item-button.selected {
    color: #bd0324;
  }

 

  .video-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
  }

  .get-content{
    display:flex;
    margin-bottom:30px;
    margin-left:190px;
  }


  @media (max-width: 768px) {
    .container {
      
      padding: 10px;
    }
  .get-content{
    display:flex;
    margin-bottom:30px;
    margin-left:10px;
  }
    
    .item-button {
      font-size: 14px;
    }
    .get-content{
      display:flex;
      margin-bottom:30px;
      margin-left:10px;
    }
  
    
    .carousel-image img {
      max-width: 100%; 
      height: auto; 
    }
  
  }
  
  
  @media (max-width: 992px) {
    .container {
      
      padding: 5px;
    }
  
    
    .item-button {
      font-size: 12px;
    }
    .video-container {
      margin-top: 10px; 
      width: 70%; 
      
    }
  
    
    .carousel-image img {
      max-width: 100%; 
      height: auto; 
    }
    .video-container {
      margin-top: 10px; 
      width: 70%; 
    }
  
    
  }

  .text{
    display:flex;
    margin-bottom:10px;
    margin-left:190px
  }

  @media screen and (max-width: 768px) {
    .text{
      display:flex;
      margin-bottom:10px;
      margin-left: 0px;
      
    }
  }
  
  @media screen and (max-width: 480px) {
    .text{
      display:flex;
      margin-bottom:10px;
      margin-left: 0px;
    }
  }

 
  
  
  