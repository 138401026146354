.investpartner-heading {
  font-size: 2rem;
  font-weight: bold;
  color: #bd0324;
  margin-top: 2rem;
  text-align: center;
}

.descrip-text {
  color: #000000;
  font-weight: bold;
  margin-bottom: 3rem;
  margin-top: 1rem;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-size: 1.2rem;
}

.invest-partner {
  display: flex;
  flex-direction: row;
  padding-top: 15rem;
  padding-bottom: 12rem;
  justify-content: space-between;
 
}

.section {
  width: 48%;
}

.image-row {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap:20px;
}

.image-icons {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 28px;
  margin: 20px 0;
  margin-top: 12rem;
}

.icon-image {
  height: 110px;
  width: 110px;
}

.partner-image {
  width: 230px;
  height: auto;
}

@media screen and (max-width: 768px) {
  .invest-partner {
    flex-direction: column;
    align-items: center;
  }

  .section {
    width: 100%;
    margin-bottom: 2rem;
  }
}

@media screen and (max-width: 480px) {
  .invest-partner {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
}
