.description {
  display: flex;
  flex-direction: column;
  justify-content: center;

  /* text-align: start; 
   padding-left: 90px;
   padding-top: 60px; 
    */
}
.description1 {
  margin-top: 140px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.expert {
  margin-left: 100px;
  font-size: 20px;
  color: #bd0324;
  font-weight: bold;
}
.team-member {
  position: relative;
  display: inline-block;
  
}
.marleft {
  margin-left: 50px;
}

.marleft1 {
  margin-left: 310px;
}
.image-container {
  position: relative;
  overflow: hidden;
}

.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
  color: white;
  padding: 10px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  display: flex;
  justify-content: flex-start;
  text-align: start;
  flex-direction: column;
}

.team-member:hover .overlay {
  opacity: 1;
}

.name {
  margin: 0;
  font-weight: bold;
}

.linkedin-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  display: none;
}

.team-member:hover .linkedin-icon {
  display: block;
}

.linkedin-icon img {
  width: 20px;
  height: 20px;
}
.Teamm {
  font-size: 35px;
  font-weight: bold;
  margin-left: 100px;
}

@media only screen and (max-width: 768px) {
  .description1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .Teamm {
    text-align: center;
    margin-left: 0px;
  }
}

@media (max-width: 768px) {
  .team-heading {
    text-align: center;
    margin-bottom: 10px;
  }

  .team-heading2 {
    text-align: center;
    margin-bottom: 10px;
  }
  .team-member {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .overlay {
    margin-top: 10px;
  }
}

@media (min-width: 992px) {
  .team-heading {
    display: inline-block;
    /* background-color: #fcfcfc; */
    padding: 10px;
    margin-right: 20px;
    width: 228px;
    font-size: 20px;
    height: 270px;
  }
}

.space {
  margin-left: 50px;
}

.team-heading2 {
  font-size: 20px;
  /* background-color:#fcfcfc; */
  width: 228px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  height: 260px;
  margin-top: 10px;
  margin-left: 30px;
}

.director-section {
  display: flex;
  align-items: center;
  padding: 20px;
  text-align: center;
}

.team-heading {
  margin: 0;
  color: #000;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 30px;
  margin-left: 50px;
}

@media (max-width: 768px) {
  .director-section {
    flex-direction: column;
    align-items: center;
  }
}

.people-text-content {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  text-align: start;
  padding-top: 180px;
  padding-left: 180px;
}

.people-heading {
  color: #bd0324;
  font-size: 24px;
  font-weight: bold;
}

.people-para {
  font-size: 30px;
  font-weight: bold;
  width: 80%;
}

@media screen and (max-width: 768px) {
  .people-text-content {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    text-align: start;
    padding-top: 180px;
    padding-left: 30px;
  }
}

@media screen and (max-width: 480px) {
  .people-text-content {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    text-align: start;
    padding-top: 170px;
    padding-left: 30px;
  }
  .people-para {
    font-size: 20px;
    font-weight: bold;
    width: 100%;
  }
}

.people-alignment {
  display: flex;
  flex-direction: row;
}
.company-directors-heading {
  font-size: 19px;
  display: flex;
  align-items: center;
  margin: 39px;
}

.team-members-heading {
  font-size: 19px;
  display: flex;
  align-items: center;
  margin: 82px;
}
.people-alignment2 {
  display: flex;
  flex-direction: row;
  margin-left: 207px;
}


@media (max-width: 768px) {
  .people-alignment {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.adviser-heading {
  font-size: 19px;
  display: flex;
  align-items: center;
  margin: 105px;
  margin-left: 30px;
}
.teamLead-heading {
  font-size: 19px;
  display: flex;
  align-items: center;
  margin: 80px;
  margin-left: 30px;
}

@media screen and (max-width: 768px) {
  .adviser-heading {
    font-size: 19px;
    display: flex;
    align-items: center;
    margin: 20px;
  }
  .people-alignment2 {
    display: flex;
    flex-direction: column;
    margin-left: 0px;
  }
}

@media screen and (max-width: 480px) {
  .adviser-heading {
    font-size: 19px;
    display: flex;
    align-items: center;
    margin: 20px;
  }
  .people-alignment2 {
    display: flex;
    flex-direction: column;
    margin-left: 0px;
  }
}

.interns-heading {
  font-size: 19px;
  display: flex;
  align-items: center;
  margin: 80px;
  margin-left: 65px;
}

.key-leadership {
  height: 260px;
  width: 225px; 
  background-color: transparent; 
  font-size: 20px;
  text-align: center;
  display: flex;
  align-items: center; 
  justify-content: center;
  margin-right: 40px; 
  margin-top: 18px;
}

.team-members-container {
  display: flex;
  flex-wrap: wrap;
}

.team-member {
  margin-right: 20px; 
}
/* .empty-card {
  display: none; 
  width: 225px;
  height: 260px; 
  background-color: transparent;
  margin-right: 40px; 
  margin-top: 18px;
}

.empty-card-content {
  width: 100%;
  height: 100%;
} */


