.scroll-top-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #333;
  color: white;
  border: none;
  border-radius: 80%;
  padding: 10px;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  -webkit-border-radius: 80%;
  -moz-border-radius: 80%;
  -ms-border-radius: 80%;
  -o-border-radius: 80%;
}

.scroll-top-button.visible {
  opacity: 1;
}

.go-top {
  position: fixed;
  cursor: pointer;
  top: 0;
  right: 15px;
  background-color: #212121;
  z-index: 4;
  width: 40px;
  text-align: center;
  height: 42px;
  line-height: 42px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.9s;
  transition: 0.9s;
  border-radius: 20%;
  -webkit-border-radius: 20%;
  -moz-border-radius: 20%;
  -ms-border-radius: 20%;
  -o-border-radius: 20%;
}

.go-top i {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
  color: #ffffff;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  font-size: 20px;
}

.go-top i:last-child {
  opacity: 0;
  visibility: hidden;
  top: 60%;
}

.go-top::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: #bd0324;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  border-radius: 20%;
  -webkit-border-radius: 20%;
  -moz-border-radius: 20%;
  -ms-border-radius: 20%;
  -o-border-radius: 20%;
}

.go-top:hover {
  color: #ffffff;
  background-color: #bd0324;
}

.go-top:hover::before {
  opacity: 1;
  visibility: visible;
}

.go-top:hover i:first-child {
  opacity: 0;
  top: 0;
  visibility: hidden;
}

.go-top:hover i:last-child {
  opacity: 1;
  visibility: visible;
  top: 50%;
}

.go-top:focus {
  color: #ffffff;
}

.go-top:focus::before {
  opacity: 1;
  visibility: visible;
}

.go-top:focus i:first-child {
  opacity: 0;
  top: 0;
  visibility: hidden;
}

.go-top:focus i:last-child {
  opacity: 1;
  visibility: visible;
  top: 50%;
}

.go-top.active {
  top: 90%;
  -webkit-transform: translateY(-98%);
  transform: translateY(-98%);
  opacity: 1;
  visibility: visible;
}


