/* .parent_div {
  display: flex;
} */

.container {
  font-family: "Open Sans", sans-serif;
  overflow: hidden !important;
}
.parent {
  margin-left: 70px;
  margin-right: 70px;
}
.header-content {
  /* display: flex; */
  /* align-items: center; */
  justify-content: space-between;
  margin: 0px;
  background-color: #fff;
}

/* header {
  color: #0e0e0e;
  height: 60px;
  padding: 2px;
  background: #f5f6f6;
} */

.dashboard-text {
  font-size: 20px;
  flex: 1;
  text-align: center;
}

.BannerToggleButton {
  font-size: 20px;
  border: none;
  border-radius: 50%;
  background: none;
  color: #050505;
  cursor: pointer;
  transition: all 0.8s ease-in-out;
}

.map-main-container {
  width: 100vw;
  display: flex;
  flex-direction: column;
}

/* Add the following style to the SidebarDashboard component */
.sidebar-dashboard {
  width: 280px; /* Adjust the width as needed */
  background: #fff;
  transition: margin-left 1s;
  z-index: 1;
}

.content-container {
  display: flex;
  flex-direction: row;
}

@media screen and (max-width: 768px) {
  .content-container {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: 480px) {
  .content-container {
    display: flex;
    flex-direction: column;
  }
}
