
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";


.banner-home {
  position: relative;
  background-image: url("https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/home_icons_new/HomeScreenNewBg.png");
  background-size: cover;
  background-position: center;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5); 
}

.main-heading {
  margin: 0;
  padding: 20px;
  text-align: start;
  width: 60%;
  
}
@media screen and (max-width: 480px) {
 

  .main-heading {
    text-align: start;
    justify-content: start;
    align-items: start;
    width: 100%;
  
  }

}


.image-alignment {
  position: absolute;
  margin-left: 2rem;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}
.mobile-image {
  display: none; 
}

@media (max-width: 768px) {
  .images-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .images-container > img {
    display: none; 
  }
  .images-container > .mobile-image {
    display: block; 
    width: 20%;
    text-align: center;
  }
}
.image-alignment.visible {
  opacity: 1; 
}




.button-container {
  margin-top: 20px; 
  display: flex;
  flex-direction: column;
  align-items: start;
  position: contain;
  
}
.button-container2 {
  margin-top: 20px; 
  
 
  
}
.extra-button {
  background: linear-gradient(90deg, #ff7e5f, #feb47b);
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
  transition: background 0.3s ease;
  margin-top: 60px;
}

.extra-button:hover {
  background: linear-gradient(90deg, #feb47b, #ff7e5f);
}


.contact-button {
  background-color: green;
  color: white;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 5px;
  font-size: 16px;
  text-align: center;
  margin-left: 2rem;
  margin-top: 12rem;
 
}

.contact-button:hover {
  background-color: #fff;
  color: green;
  font-weight: bold;
}
.scroll-styles {
  
  margin-top: 10rem;
  font-size: 1rem;
  transform: rotate(-90deg);
  color: #fff;
  width: 10%;
  margin-left: 3.125rem;
}

@media (max-width: 768px) {
  .banner-home {
    height: 100vh;
    background-size: cover;
    background-position: center;
  }
  .scroll-styles {
    display: none;
  }
  .banner-text1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
  }
}

.banner-text1 {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
}

.banner-container {
  display: flex;
}


.banner-home h1 {
  color: white;
  font-size: 2.5rem;
  margin-top: 3rem;
}

.banner-home p {
  color: white;
}

@media (max-width: 768px) {
  .banner-home h1 {
    font-size: 1.875rem;
    margin-top: 3.125em;
  }

  .banner-home p {
    font-size: 2rem;
  }
}

.banner-h1 {
  color: white;
  font-size: 3.125rem;
  margin-top: 3.125em;
}

.banner p {
  color: white;
  font-size: 1.25rem;
}

.image-container1 {
  display: flex;
  /* justify-content: center; */
  margin-top: 1.25rem;

 
}
.image-icons {
  display: flex;
  justify-content: start; 
}

.responsive-image {
  height: 40%;
  width: 40%;
  margin: 20px;
}



.row-container {
  display: flex;
  flex-direction: row;
  padding: 0.9375rem;
}

.column-card {
  background-color: hsl(0, 2%, 84%);
  padding: 0.9375rem;
  margin: 0 0.625rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.image-wrapper {
  margin-bottom: 0.9375rem;
}

.card-text {
  text-align: center;
}

.para-text {
  margin-right: 1.5rem;
  margin-top: 1rem;
  font-size: 1.25rem;
  width: 97%;
}

@media screen and (max-width: 768px) {
  .para-text {
    margin-right: 0;
    font-size: 0.875rem;
  }
}

@media screen and (max-width: 480px) {
 
 
  .banner-home {
  
    height: 140vh;
   
  }
  
  .scroll-styles {
    display: none;
  }
  .banner-text1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
  }

  .contact-button {
    background-color: green;
    color: white;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 5px;
    font-size: 16px;
    text-align: center;
    margin-top: 1rem;
    /* margin-left:120px; */
   
  }
}

@media (max-width: 1123px) {
  .scroll-styles {
    margin-right: 6.25rem;
    margin-top: 17.5rem;
    font-size: 0.9rem;
    transform: rotate(-90deg);
    color: #fff;
    
  }
}

.container1 {
  /* height: 250px; */
  width: 100%;
  border-radius: 10px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.circle {
  width: 5rem;
  height: 5rem;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container1:hover .circle {
  background-color: #bd0324;
  transition: transform 0.2s, color 0.1s;
}

.hover-effect-home {
  display: flex;
  align-items: center;
  /* transition: transform 0.3s, color 0.3s; */
  cursor: pointer;
  color: #000;
  margin: 0.625rem;
}

.hover-effect-home:hover {
  color: #bd0324;
  transition: transform 0.3s;
  cursor: default;
}

.white-card {
  filter: grayscale(0%);
  opacity: 1;
  cursor: default;
}

.white-card:hover {
  /* filter: grayscale(100%); */
  opacity: 1;
  filter: brightness(0%) invert(100%);
}

.container1:hover .circle {
  background-color: #bd0324;
  transition: background-color 0.2s, transform 0.2s;
}

.container1:hover .hover-effect-home {
  color: #bd0324;
}

.white-card {
  filter: grayscale(0%);
  opacity: 1;
}

.container1:hover .white-card {
  filter: brightness(0) invert(100%);
}

.sdg-styles {
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
 
  font-family: "Open Sans", sans-serif;

  overflow: hidden !important;
}

.header-content {
  display: flex;
  align-items: center; 
  margin: 10px;
}

header {
  color: #0e0e0e;
  height: 60px;
  padding: 2px;
  background: #f5f6f6;
}

.dashboard-text {
  font-size: 20px;
  flex: 1;
  text-align: center;
}

.BannerToggleButton {
  font-size: 20px;
  border: none;
  border-radius: 50%;
  background: none;
  color: #050505;
  cursor: pointer;
  transition: all 0.8s ease-in-out;
}

/* Home.css */
.carousel-container {
  width: 100%;
  max-width: 1190px; 
  margin: auto;
  position: relative;
  overflow: hidden;
}

.carousel-card {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  height: 280px; 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
  border-radius: 8px; 
  margin-top: 30px;
}



.carousel-content {
  position: absolute;
  bottom: 20px;
  left: 10px;
  color: #fff;
  background-color: #fff; 
  padding: 10px;
  border-radius: 8px;
  display: flex;
  flex-direction: row; 
 
}

.read-more {
  color: rgb(55, 55, 221);
  text-decoration: none;
  font-weight: bold;
}


.slick-prev, .slick-next {
  z-index: 1;
  width: 40px;
  height: 40px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
}

.slick-prev {
  left: 10px;
}

.slick-next {
  right: 10px;
}

.slick-dots {
  bottom: 10px;
  
  
}

.slick-dots li button:before {
  font-size: 12px;
  color: #9b9696;
}

.investor3{
  display: 'flex';
  flex-wrap: wrap;
  justify-content: flex-start;  
  align-items: center;
  gap: 15px;
  row-gap: 20px;
  margin: '0 auto'; 
  width: '100%';
  max-width: 1200px;
  margin-top: 32px;
}



@media (max-width: 768px) {
  .carousel-content {
    flex-direction: column; 
    align-items: center;
    text-align: center;
  }

  .carousel-image {
    max-width: 70%;
  }

  .carousel-text {
    width: 100%; 
    padding-left: 0;
    padding-right: 0;
  }

  .carousel-text p {
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .carousel-image {
    max-width: 80%;
    height: auto; 
    margin-bottom: 0px;
  }

  .carousel-text p {
    font-size: 0.8rem; 
  }
  .carousel-content {
    
    bottom: 0px;
    
   
  }
  .carousel-card {
   
    height: 380px; 
    
  }
  
  
}