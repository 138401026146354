.cookie-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px;
  background-color: #fff;
  color: #333;
  text-align: center;
  border-radius: 4px;
}

.cookie-banner button {
  background-color: #fff;
  color: #333;
  padding: 5px 10px;
  margin-left: 10px;
  cursor: pointer;
  border-radius: 2px;
  border-width: 1px;
  border-color: #333;
}

.cookie-banner a {
  text-decoration: none;
}

.cookie-heading {
  font-size: 1.5rem;
  margin-bottom: 10px;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: start;
}

.cookie-banner p {
  font-size: 0.9rem;
  margin-bottom: 15px;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: start;
}

.cookie-overlay {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 9999;
}

.cookie-overlay-message {
  color: #333;
  padding: 20px;
  background: #fff;
  border-radius: 5px;
  text-align: start;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 85%;
  margin-bottom: 20px;
}

.cookie-overlay button {
  background-color: #fff;
  color: #333;
  padding: 10px 20px;
  cursor: pointer;
  border-color: #333;
  border-width: 1px;
  border-radius: 5px;
  margin-top: 10px;
}
