.bg-careers {
  background-image: url("https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/career.png");
  background-size: cover;
  background-position: center;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.text-containerr {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: start;
  text-align: start;
}

.scroll-text {
  margin-top: 190px;
  font-size: 15px;
  transform: rotate(-90deg);
  color: #fff;
  margin-right: 140px;
}

.banner-textt {
  color: #fff;
  margin-left: 70px;
}

.banner-headingc {
  font-size: 20px;
  color: #fcb22f;
}

.banner-parac {
  font-size: 40px;
  font-weight: bold;
  /* display: flex;
    justify-content: flex-start;
    text-align: start;
    flex-direction: row; */
    width: 80%;
}
@media screen and (max-width: 480px) {
 
  .banner-parac {
    
      width: 100%;
  }

  

}

@media screen and (max-width: 768px) {
  .scroll-text {
    margin-top: 50px;
    font-size: 12px;
    transform: none;
    display: none;
  }

  .banner-textt {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;
  }
  .bg-careers {
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 100vh;
      background-size: cover;
      background-position: center; */
  }
  .banner-headingc {
    font-size: 15px;
    color: #fcb22f;
  }
  .banner-parac {
    font-size: 20px;
    font-weight: bold;
    padding: 20px;
  }
}
