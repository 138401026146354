.container {
  flex: 1;
  padding: 0 15px;
}

.row {
  display: flex;
  /* align-items: center; */
}

.col {
  flex: 1;
}

.copy-right {
  text-align: center;
  font-family: Calibri;
  font-size: 12px;
  color: #fff;
}

.footer-top-area {
  background-color: #071220;
  color: #fff;
}

.footer-top-area h3 {
  font-size: 20px;
  font-weight: 600;
  color: #fff;
}

.footer-top-area p {
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  /* margin-bottom: 20px; */
}

.footer-top-area .single-widget img {
  max-height: 70px;
}

.footer-top-area .single-widget ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-top-area .single-widget ul li a {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
}

.footer-top-area .single-widget ul li a i {
  margin-right: 10px;
}

.footer-top-area .single-widget ul li a:hover {
  color: #fff;
}
.footer-bottom-area {
  /* background-color: #0d1240; */
  padding: 15px 0;
}

.footer-bottom-area .copy-right {
  color: #d9d6d6;
}

.footer-bottom-area .copy-right i {
  position: relative;
  top: 1px;
}

.footer-bottom-area .copy-right a {
  color: #1dd3f8;
}

.footer-bottom-area .copy-right a:hover {
  color: #ffffff;
}

.footer-bottom-area .designed {
  text-align: right;
  color: #d9d6d6;
}

.footer-bottom-area .designed i {
  position: relative;
  top: 2px;
  color: #1dd3f8;
  -webkit-animation: heartBeat 1000ms infinite linear;
  animation: heartBeat 1000ms infinite linear;
}

.footer-bottom-area .designed a {
  color: #1dd3f8;
}

.footer-bottom-area .privacy {
  text-align: center;
}

.footer-bottom-area .privacy ul li {
  display: inline-block;
  padding-right: 15px;
}

.footer-bottom-area .privacy ul li a {
  color: #d9d6d6;
  position: relative;
}

.footer-bottom-area .privacy ul li a::before {
  content: "";
  position: absolute;
  top: 13px;
  left: -14px;
  width: 10px;
  height: 1px;
  background-color: #d9d6d6;
}

.footer-bottom-area .privacy ul li a:hover {
  color: #1dd3f8;
}

.footer-bottom-area .privacy ul li:first-child a::before {
  display: none;
}
.single-widget .social-icon li a:hover {
  -webkit-transform: translateX(0px);
  transform: translateX(0px);
  -webkit-animation: rubberBand 1s linear;
  animation: rubberBand 1s linear;
}

.single-widget .social-icon li {
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 0;
}

.single-widget h3 {
  color: #ffffff;
  font-size: 25px;
  position: relative;
  padding-bottom: 10px;
}

.single-widget h3::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 70%;
  height: 2px;
  background-color: #fff;
}

.single-widget h3::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 70%;
  height: 2px;
  background-color: #060c4a;
}

.footer-link {
  color: #333;
  transition: color 0.3s ease, transform 0.2s ease;
  text-decoration: none;
}

.footer-link:hover {
  color: blue;
  transform: translateY(-2px);
}

.footer-link:active {
  color: blue;
}

@media screen and (max-width: 768px) {
  .footer-text {
    margin-left: 0px;
  }
}

@media screen and (max-width: 480px) {
  .footer-text {
    margin-left: 0px;
  }
}

.contactus-text {
  margin-top: 0px;
}

.information1 {
  display: flex;
  flex-direction: row;
}

.link {
  padding: 15px 30px;
  margin: 10px;
  display: inline-block;
  color: #000;
  background: #ffce54;
  text-decoration: none;
}

.link:hover {
  text-decoration: none;
  color: #000;
}

/*===================*/
/* WOOBLE VERTICAL
/*===================*/

@-webkit-keyframes wobble-vertical-on-hover {
  16.65% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
  33.3% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
  }
  49.95% {
    -webkit-transform: translateY(4px);
    transform: translateY(4px);
  }
  66.6% {
    -webkit-transform: translateY(-2px);
    transform: translateY(-2px);
  }
  83.25% {
    -webkit-transform: translateY(1px);
    transform: translateY(1px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes wobble-vertical-on-hover {
  16.65% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
  33.3% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
  }
  49.95% {
    -webkit-transform: translateY(4px);
    transform: translateY(4px);
  }
  66.6% {
    -webkit-transform: translateY(-2px);
    transform: translateY(-2px);
  }
  83.25% {
    -webkit-transform: translateY(1px);
    transform: translateY(1px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
.wobble-vertical-on-hover {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}
.wobble-vertical-on-hover:hover,
.wobble-vertical-on-hover:focus,
.wobble-vertical-on-hover:active {
  -webkit-animation-name: wobble-vertical-on-hover;
  animation-name: wobble-vertical-on-hover;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

.link {
  padding: 15px 30px;
  margin: 10px;
  display: inline-block;
  color: #000;
  background: #ffce54;
  text-decoration: none;
}

.link:hover {
  text-decoration: none;
  color: #000;
}

/*===================*/
/* WOOBLE HORIZONTAL
/*===================*/

@-webkit-keyframes wobble-horizontal-on-hover {
  16.65% {
    -webkit-transform: translateX(8px);
    transform: translateX(8px);
  }
  33.3% {
    -webkit-transform: translateX(-6px);
    transform: translateX(-6px);
  }
  49.95% {
    -webkit-transform: translateX(4px);
    transform: translateX(4px);
  }
  66.6% {
    -webkit-transform: translateX(-2px);
    transform: translateX(-2px);
  }
  83.25% {
    -webkit-transform: translateX(1px);
    transform: translateX(1px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes wobble-horizontal-on-hover {
  16.65% {
    -webkit-transform: translateX(8px);
    transform: translateX(8px);
  }
  33.3% {
    -webkit-transform: translateX(-6px);
    transform: translateX(-6px);
  }
  49.95% {
    -webkit-transform: translateX(4px);
    transform: translateX(4px);
  }
  66.6% {
    -webkit-transform: translateX(-2px);
    transform: translateX(-2px);
  }
  83.25% {
    -webkit-transform: translateX(1px);
    transform: translateX(1px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
.wobble-horizontal-on-hover {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}
.wobble-horizontal-on-hover:hover,
.wobble-horizontal-on-hover:focus,
.wobble-horizontal-on-hover:active {
  -webkit-animation-name: wobble-horizontal-on-hover;
  animation-name: wobble-horizontal-on-hover;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

.icons {
  display: flex;
  flex-direction: row;
}

@media screen and (max-width: 768px) {
  .contactus-text {
    margin-top: 20px;
  }
}

@media screen and (max-width: 480px) {
  .contactus-text {
    margin-top: 20px;
  }
}

.hover-phone-link {
  text-decoration: none;
  color: #fff;
  transition: color 0.3s;
}

.hover-phone-link:hover {
  color: #00acee;
}

.hover-effect {
  display: flex;
  align-items: center;
  transition: transform 0.3s, color 0.3s;
  cursor: pointer;
  color: #fff;
  margin: 10px;
}

.hover-effect:hover {
  transform: translateX(10px);
  color: #00acee;
}

.arrow-icon {
  transition: transform 0.3s;
}

.hover-effect:hover .img {
  transform: translateX(10px);
  color: #00acee;
}
