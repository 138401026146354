.client-content {
  background-color: #fff;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  align-items: center;
  align-content: flex-start;
  padding: 2rem;
  margin-top: 10rem;
}

.heading-text {
  font-size: 2rem;
  font-weight: bold;
  color: #bd0324;
  margin-top: 2rem;
}

.description-text {
  color: #000000;
  font-weight: bold;
  margin-bottom: 3rem;
  margin-top: 1rem;
  width: 80%;
  
  text-align: center;
  font-size: 1.2rem;
}

@media screen and (max-width: 768px) {
  .bg-content {
    padding: 1rem;
    padding-top: 15rem;
  }

  .heading-text {
    font-size: 2.4rem;
  }

  .description-text {
    font-size: 1.6rem;
    width: 100%;
    
  
   
   
  }
  
}

@media screen and (max-width: 480px) {
  .heading-text {
    font-size: 1rem;
  }

  .description-text {
    font-size: 1rem;
  }
  .image-row {
    justify-content: center;
    align-items: center;
    text-align: center;
    align-content: center;
  }
  .client-content {
    
    padding: 1rem;
    margin-top: 7em;
  }
  
}

.clients-align {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

@media (max-width: 768px) {
  .clients-align {
    flex-direction: column;
  }
  .image-row {
    justify-content: flex-start;
    align-items: flex-start;
    text-align: start;
    align-content: flex-start;
  }
}

.image-row {
  justify-content: center;
  align-items: flex-start;
  text-align: start;
  align-content: flex-start;
}
