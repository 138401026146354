.parentDiv {
  overflow-y: auto;
  overflow-x: hidden;
  scroll-behavior: smooth;
  height: 100vh;
  
}
.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
