.image-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.components-container {
  display: flex;
  flex-direction: row;
}

@media (max-width: 768px) {
  .components-container {
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 768px) {
  .randd-container {
    display: none;
  }
  .randd-small-medium-screen {
    display: block;
    text-align: center;
  }
}

@media (min-width: 769px) {
  .randd-small-medium-screen {
    display: none;
  }
  .randd-large-screen {
    display: block;
  }
}

.text-content {
  margin-left: 10px;
  color: #fff;
}

.title {
  font-size: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  text-align: start;
  color: #fcb22f;
}

.description {
  font-size: 40px;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  text-align: center;
  margin-right: 390px;
}

@media (max-width: 768px) {
  .title {
    font-size: 20px;
  }

  .description {
    font-size: 30px;
  }

  .text-content {
    margin-left: 200px;
  }
}

.bg-RandD {
  background-image: url("https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/R&D2.png");
  background-size: cover;
  background-position: center;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.scroll-text {
  margin-top: 190px;
  font-size: 15px;
  transform: rotate(-90deg);
  color: #fff;
  margin-right: 140px;
}

.banner-text7 {
  color: #fff;
  /* margin-right: 330px; */
  /* text-transform:capitalize ; */
  width:80%;
}
@media screen and (max-width: 480px) {
  .banner-text7 {
   
    width: 100%;
  }
}


.banner-heading {
  font-size: 20px;
  color: #fcb22f;
}

.banner-para7 {
  font-size: 40px;
  font-weight: bold;
  /* display: flex;
  justify-content: flex-start;
  text-align: start;
  flex-direction: row; */
}

@media screen and (max-width: 768px) {
  .scroll-text {
    margin-top: 50px;
    font-size: 12px;
    transform: none;
    display: none;
  }

  .banner-text7 {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;
  }
  .bg-projects {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .banner-heading {
    font-size: 15px;
    color: #fcb22f;
  }
  .banner-para7 {
    font-size: 20px;
    font-weight: bold;
    padding: 20px;
  }
}
