.row > * {
  flex-shrink: 0;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.tab-link.active {
  font-weight: bold;
}

.tab-link:hover .tab-link-icon {
  background-color: #bd0324;
}

.tab-link:hover {
  font-weight: bold;
}

.tab-link {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: start;
  text-decoration: none;
}

.tab-link .tab-link-icon img.tab-link.active {
  max-width: 40px;
  max-height: 40px;
}

.tab-content-container {
  display: flex;
  gap: 15px;
  align-items: center;
}

.full-width {
  margin-bottom: 1%;
  margin-left: 190px;
}
.tab-link-icon {
  background-color: #c9c9c9;
  border-radius: 50%;
  padding: 10px;
  transition: background-color 0.3s;
  height: 50px;
  width: 50px;
}

.tab-link-icon:hover {
  background-color: #bd0324;
}
.tab-link-icon.active {
  background-color: #bd0324;
}
