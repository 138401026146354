.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.heading-line {
  width: 100%;
  height: 6px;
  background-color: #ddd;
  margin: 20px 0;
  display: flex;
  position: relative;
  display: flex;
}

.heading-line::before,
.heading-line::after {
  content: "";
  width: 50%;
  height: 100%;
  background-color: #ddd;
  transition: background-color 0.3s ease-in-out;
}

@media (max-width: 480px) {
  .heading-line::before,
  .heading-line::after {
    width: 30%;
  }
}

.item-buttons {
  display: flex;
  gap: 430px;
}

@media screen and (max-width: 768px) {
  .item-buttons {
    display: flex;
    gap: 20px;
  }
}

@media screen and (max-width: 480px) {
  .item-buttons {
    display: flex;
    gap: 20px;
  }
}
.heading-line.product::before,
.heading-line.theme::after {
  background-color: #ddd;
}

.heading-line.theme::before,
.heading-line.product::after {
  background-color: #bd0324;
}

.item-button {
  cursor: pointer;
  padding: 10px 20px;
  margin: 0 10px;
  font-size: 16px;
  color: #333;
  transition: color 0.3s ease-in-out;
}

.item-button.selected {
  color: #bd0324;
}

@media (max-width: 768px) {
  .carousel-container {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .text-content {
    margin-left: 0;
    margin-right: 380px;
  }

  .heading-line {
    height: 4px;
    margin: 10px 0;
  }

  .heading-line::before,
  .heading-line::after {
    width: 50%;
  }
}

.items-heading {
  font-weight: bold;
  font-size: 25px;
  margin-right: 560px;
}

@media (max-width: 768px) {
  .item-button {
    margin-right: 20px;
  }
  .text-line {
    display: flex;
    margin-bottom: 30px;
    margin-left: 0;
  }
  .text-line2 {
    font-size: 20px;
    margin-left: 20px;
    margin-top: 10px;
  }
}

.text-line {
  display: flex;
  margin-bottom: 30px;
  margin-left: 190px;
}

.text-line2 {
  font-size: 20px;
  margin-left: 20px;
  margin-top: 10px;
}

@media (max-width: 480px) {
  .text-line {
    display: flex;
    margin-bottom: 30px;
    margin-left: 190px;
  }

  .text-line2 {
    font-size: 20px;
    margin-left: 20px;
    margin-top: 10px;
  }
}

