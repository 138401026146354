/* .not-found-container {
  display: flex;
  justify-content: center;
  align-items: center;

  padding-top: 10rem;
  height: 100vh;
}

.not-found-container img {
  max-width: 70%;
  margin-right: 30px;
}

button {
  padding: 10px 20px;
  font-size: 16px;
}

.not-found-text {
  margin-left: 90px;
}
.not-found-heading1 {
  font-size: 4em;
  font-weight: bold;
}

.not-found-heading2 {
  font-size: 1em;
  font-weight: bold;
}
.not-found-para {
  font-size: 1em;
}
.not-found-btn {
  background-color: #bd0324;
  border-radius: 3px;
  border-width: 0px;
  color: #fff;
  width: 50%;
}

@media screen and (max-width: 768px) {
  .not-found-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 10rem;
  }
}

@media screen and (max-width: 480px) {
  .not-found-container {
    justify-content: center;
    align-items: center;
    display: flex;
    padding-top: 10rem;

    flex-direction: column;
  }
  .not-found-container img {
    max-width: 70%;
    align-items: center;
    margin-right: 0px;
    justify-content: center;
  }
} */
.not-found-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10rem;
  height: 100vh;
}

.not-found-container img {
  max-width: 70%;
  margin-right: 30px;
}

button {
  padding: 10px 20px;
  font-size: 16px;
}

.not-found-text {
  margin-left: 90px;
}

.not-found-heading1 {
  font-size: 4em;
  font-weight: bold;
}

.not-found-heading2 {
  font-size: 1em;
  font-weight: bold;
}

.not-found-para {
  font-size: 1em;
}

.not-found-btn {
  background-color: #bd0324;
  border-radius: 3px;
  border-width: 0px;
  color: #fff;
  width: 50%;
}

@media screen and (max-width: 768px) {
  .not-found-container {
    flex-direction: column;
    text-align: center;
  }
  .not-found-text {
    margin-left: 10px;
    padding-bottom: 16rem;
  }
  .not-found-container img {
    max-width: 70%;
    margin-right: 0px;
    padding-top: 6rem;
  }
}

@media screen and (max-width: 480px) {
  .not-found-container {
    flex-direction: column;
    text-align: center;
  }

  .not-found-container img {
    max-width: 70%;
    margin-right: 0px;
  }
  .not-found-text {
    margin-left: 0px;
    padding-bottom: 10rem;
  }
}
