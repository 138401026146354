.side_banner {
  position: relative; /* Change to relative positioning */
  /* height: 95vh; */
  width: 290px;
  background: #fff;
  transition: margin-left 1s;
  z-index: 1;
  margin-top: 0px;
  /* margin-left: 50px; */
}

.checkbox-container {
  margin-left: 10px;
  margin-bottom: 16px;
}

/* BULGING EFFECT */
/* Default styles for checkbox and input */
input[type="checkbox"],
input[type="text"] {
  margin-right: 8px;
  transition: transform 0.2s ease-in-out;
}

/* Styles for the input when hovered */
input[type="text"]:hover,
.checkbox-container:hover input[type="checkbox"] {
  transform: scale(1.3);
}

/* Add this rule to style the text when the checkbox is checked */
input[type="checkbox"]:checked + label {
  color: #70cae6;
  font-weight: bold;
}

/* Add custom styles for the "Select All" button */
.btn-select-all {
  background-color: #b9e4f1;
  color: #000000;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  margin-bottom: 10px;
  margin-top: 20px;
}

.btn-select-all:hover {
  background-color: #d0d4d8;
}
