.bg-container {
  background-image: url("https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/capabilities.png");
  background-size: cover;
  background-position: center;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.image-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.components-container {
  display: flex;
  flex-direction: row;
}

.bg-Capabilities {
  background-image: url("https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/Capabilities/capabilities.png");
  background-size: cover;
  background-position: center;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.scroll-text {
  margin-top: 190px;
  font-size: 15px;
  transform: rotate(-90deg);
  color: #fff;
}

.banner-text {
  color: #fff;
  /* margin-right: 330px;
   */
}

.banner-heading {
  font-size: 20px;
  color: #629927;
}

.banner-para3 {
  font-size: 40px;
  font-weight: bold;
  color: #fff;
  width: 70%;
 
}

@media screen and (max-width: 768px) {
  .scroll-text {
    margin-top: 50px;
    font-size: 12px;
    transform: none;
    display: none;
  }

  .banner-text3 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
  }
  .banner-heading {
    font-size: 15px;
    color: #629927;
  }
  .banner-para3 {
    font-size: 20px;
    font-weight: bold;
    width: 100%;
  }
  .bg-Capabilities {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (max-width: 480px) {
  .banner-text3 {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* color: #fff; */
  }
  .bg-Capabilities {
    /* display: flex;
     justify-content: center;
     width: 100vw;
     height: 100vh; */
    align-items: center;
  }
  .banner-para3 {
    font-size: 17px;
    font-weight: bold;
    padding: 20px;
  }
}
