.image-container {
  display: flex;

  margin-top: 20px;
}
.components-container {
  display: flex;
  /* flex-direction: row; */
}
.radio-option {
  padding: 10px;
}

.radio-option label.selected {
  color: #fcb22f;
  display: block;
}
.checkbox-option.selected {
  background-color: #fcb22f;
}

.radio-option.selected label::before {
  color: orange;
  border-color: orange;
}

.image-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.bg-projects {
  background-image: url("https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/project_bg.png");
  background-size: cover;
  background-position: center;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.scroll-text {
  margin-top: 190px;
  font-size: 15px;
  transform: rotate(-90deg);
  color: #fff;
  margin-right: 140px;
}

.banner-text4 {
  color: #fff;
  /* margin-right: 330px; */
  /* text-transform:capitalize ; */
}

.banner-heading {
  font-size: 20px;
  color: #629927;
}

.banner-para4 {
  font-size: 40px;
  font-weight: bold;
  /* display: flex;
  justify-content: flex-start;
  text-align: start;
  flex-direction: row; */
  width:80%;
}

@media screen and (max-width: 768px) {
  .scroll-text {
    margin-top: 50px;
    font-size: 12px;
    transform: none;
    display: none;
  }

  .banner-text4 {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;
  }
  .bg-projects {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .banner-heading {
    font-size: 15px;
    color: #629927;
  }
  .banner-para4 {
    font-size: 20px;
    font-weight: bold;
    padding: 20px;
    width:100%;
  }
}

.placingg {
  display: flex;
  text-align: start;
  justify-content: flex-start;
  align-content: flex-start;
}

.project-text {
  margin-top: 100px;
  margin-left: 130px;
  font-size: 32px;
  text-transform: capitalize;
  margin-bottom: 50px;
  font-weight: bold;
  width: 80%;
}

@media screen and (max-width: 768px) {
  .project-text {
    margin-top: 20px;
    margin-left: 10px;
    font-size: 20px;
  }
}

@media screen and (max-width: 480px) {
  .project-text {
    margin-top: 30px;
    margin-left: 30px;
    font-size: 20px;
    width: 90%;
  }
}

@media screen and (max-width: 768px) {
  .radio-option {
    margin-right: 2%;
  }
}

@media screen and (max-width: 480px) {
  .radio-option {
    margin-right: 0;
  }
}

.project-content {
  display: flex;
  flex-direction: column;
  padding: "20px";
  justify-content: center;
  font-size: 14px;
  margin-left: 120px;
}

@media screen and (max-width: 768px) {
  .project-content {
    display: flex;
    flex-direction: column;
    padding: "20px";
    justify-content: flex-start;
    font-size: 10px;
  }
}

@media screen and (max-width: 768px) {
  .project-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}

@media screen and (max-width: 480px) {
  .project-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    margin-left: 80px;
  }
}

@media (min-width: 768px) {
  .project-align {
    display: flex;
    flex-direction: row;
  }
}

@media (max-width: 768px) {
  .project-align {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: 480px) {
  .project-imagess {
    margin-left: 0px;
    margin-bottom: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.project-image {
  max-width: 100%;
  height: auto;
}

@media (max-width: 768px) {
  .project-image {
    max-width: 80%;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}

.project-imagess {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 90px;
  margin-bottom: 40px;
  flex-direction: row;
}

@media (max-width: 768px) {
  .image-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .project-image {
    max-width: 90%;
    height: auto;
    margin: 10px;
  }
}

.project-image-container {
  position: relative;
  display: inline-block;
  margin: 10px;
  width: 460px; /* Set a fixed width for all images */
  height: 500px; /* Set a fixed height for all images */
}

.project-image {
  display: block;
  width: 100%;
  height: 100%; /* Ensure image covers the entire container */
  object-fit: cover; /* Maintain aspect ratio and cover container */
}

.image-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.2); /* Black background with some transparency */
  color: white;
  text-align: center;
  padding: 10px;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.project-image-container:hover .image-overlay {
  opacity: 1;
}

.image-gallery {
  display: flex;
  justify-content: center; /* Center images horizontally */
  align-items: center; /* Center images vertically if needed */
  margin: 20px 0; /* Optional margin around the image row */
}

.image-row-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap; /* Ensure images stay in a single line */
}

.gallery-image {
  margin-left: 10px;
  margin-bottom: 10px;
  margin-right: 30px;
  height: 450px; /* Adjust height as needed */
  width: 400px; /* Maintain aspect ratio */
  object-fit: cover; /* Cover the container area while maintaining aspect ratio */
}
