.menu-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: 10%;
  border: 1px solid #e4dfdf;
  cursor: pointer;
  transition: border-color 0.5s ease, box-shadow 0.5s ease, transform 0.5s ease;
  margin-right: 40px;
  margin-bottom: 20px;
}

.bg-nav-bar {
  background-color: transparent;
  height: 90px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  font-size: 1.2rem;
  top: 0;
  opacity: 1;
  z-index: 999;
  transition: 0.3s ease-in-out;
  
}

.navbar-active {
  background-color: #fff;
}

.sticky-header {
  position: fixed;
  top: 0;
  width: 100%;
}

.menu-button.close-touched {
  border-color: #d7d4d4;
  box-shadow: 0 0 8px rgba(170, 175, 170, 0.4);
  transform: scale(1.1);
  margin-right: 50px;
  padding: 5px;
  margin-left: 80%;
}

.menu-button svg {
  margin-left: 2px;
}
.logo {
  width: 70%;
}


.side-nav {
  position: fixed;
  top: 0;
  right: -400px;
  width: 25%;
  height: 100%;
  background-color: #fff;
  transition: right 0.5s ease-in-out;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  z-index: 9999;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 20px;
  pointer-events: auto;
}



.side-nav a {
  text-decoration: none;
  color: #000;
  /* margin-top: 30px; */
  margin-bottom: 8px;
  font-size: 24px;
  transition: color 0.3s ease;
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-weight: bold;
}

.side-nav a:hover,
.side-nav a.active {
  color: #bd0324;
  text-decoration: underline;
}

.side-nav-open {
  right: 0;
}



.logo-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 10px;
}

.logo-container img {
  max-width: 100%;
  height: auto;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .menu-button {
    width: 60px;
    height: 60px;
    margin-right: 10px;
    margin-left: auto;
  }

  .logo {
    width: 60%;
  }

  .side-nav {
    width: 200px;
  }

  .side-nav a {
    font-size: 20px;
  }
}

@media (max-width: 480px) {
  .menu-button {
    width: 50px;
    height: 50px;
  }

  .menu-button.close-touched {
    border-color: gray;
    box-shadow: 0 2px #272a2a66;
    transform: scale(1.1);
    margin-right: 50px;
  }

  .side-nav-open {
    right: 0;
  }

  .side-nav a {
    color: #000;
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
}

@media (max-width: 300px) {
  .logo {
    width: 50%;
  }
}

.hover-effect-header {
  display: flex;
  align-items: center;
  transition: transform 0.3s, color 0.3s;
  cursor: pointer;
  color: gray;
  font-size: 20px;
  font-weight: initial;
}

.hover-effect-header:hover,
.hover-effect-header.active {
  color: #bd0324;
}


.overlay-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9998;
  pointer-events: auto;
}

@media screen and (max-width: 768px) {
  .hover-effect-header {
    display: flex;
    align-items: center;
    transition: transform 0.3s, color 0.3s;
    cursor: pointer;
    color: gray;
    font-size: 15px;
    font-weight: initial;
  }
  .menu-button.close-touched {
    border-color: #d7d4d4;
    box-shadow: 0 0 8px rgba(170, 175, 170, 0.4);
    transform: scale(1.1);
    margin-right: 10px;
    padding: 5px;
    margin-left: 72%;
  }
}

@media screen and (max-width: 480px) {
  .hover-effect-header {
    display: flex;
    align-items: center;
    transition: transform 0.3s, color 0.3s;
    cursor: pointer;
    color: gray;
    font-size: 15px;
    font-weight: initial;
  }
  .menu-button.close-touched {
    border-color: #d7d4d4;
    box-shadow: 0 0 8px rgba(170, 175, 170, 0.4);
    transform: scale(1.1);
    margin-right: 10px;
    padding: 5px;
    margin-left: 72%;
  }
}

.no-scroll {
  overflow: hidden;
}




/* General container styling */
.menu-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 26px;
  flex: 1;
  margin-right: 70px;
}


.nav-link {
  text-decoration: none;
  padding: 5px 10px;
  font-size: 16px; 
  transition: color 0.3s ease;
}

.nav-link:hover {
  color: #bd0324;
}


.dropdown-container {
  position: relative;
  margin: 0 40px;
}

.dropdown-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 10px; 
  padding: 0;
  margin: 9px;
  transition: color 0.3s ease;
}


.dropdown-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
  color: #fff;
  background-color: 'transparent'; 
  border-radius: 4px;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 10;
  width: 200px;
}

.dropdown-content.expanded {
  max-height: 300px;
}

.dropdown-content.white-bg {
  color: #000; 
  background-color: #fff; 
}


.dropdown-list {
  list-style-type: none;
  padding: 10px;
  margin: 0;
}

.dropdown-list-item {
  padding: 8px;
  cursor: pointer;
  border-bottom: 1px solid #ddd;
}

.dropdown-list-item a {
  text-decoration: none;
  color: inherit;
  transition: color 0.3s ease;
}

.dropdown-list-item a.active {
  color: #bd0324;
}


.dropdown-list-item a {
  text-decoration: none;
  font-size: 14px; 
  transition: color 0.3s ease;
}

.dropdown-list-item a.active {
  color: #bd0324;
}


@media (max-width: 768px) {
  .menu-container {
    flex-direction: row;
    align-items: flex-start;
    gap: 20px;
  }

  .dropdown-container {
    margin: 0 20px;
  }

  .nav-link {
    font-size: 14px; 
    margin-right: 10px;
  }

  .dropdown-button {
    font-size: 16px; 
  }

  .dropdown-list-item a {
    font-size: 12px; 
  }
}

@media (max-width: 768px) {
  .nav-link {
    font-size: 10px !important; 
    
  }
}

@media (max-width: 480px) {
  .nav-link {
    font-size: 10px !important; 
    
  }
  .menu-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;
    flex: 1;
    margin-right: 10px;
  }
}


.dropdown-container {
  display: flex;
  align-items: center; 
  gap: 4px; 
  margin: 0 40px;
}

.dropdown-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px; 
  padding: 0;
  margin: 0; 
  transition: color 0.3s ease;
}

.nav-link {
  text-decoration: none;
  padding: 5px 10px;
  font-size: 16px;
  transition: color 0.3s ease;
}

@media (max-width: 480px) {
  .dropdown-container {
    gap: 2px; 
  }

  .nav-link,
  .dropdown-button {
    font-size: 12px; 
  }
}
@media (max-width: 480px) {
  .menu-container {
    display: flex;
    justify-content: space-between; 
    align-items: center;
    gap: 2px; 
  }
  
  .nav-link {
    white-space: nowrap; 
    text-align: center; 
    font-size: 12px; 
  }

  .dropdown-container {
    gap: 2px;
    margin: 0 20px; 
  }

  .dropdown-button {
    font-size: 10px; 
    margin: 0; 
  }

  .dropdown-content {
    width: 150px; 
  }

  .dropdown-list-item {
    padding: 5px; 
  }

  .dropdown-list-item a {
    font-size: 10px; 
  }
}
