.capaa {
  margin-left: 90px;
  margin-top: 90px;
  margin-bottom: 50px;
  transition-duration: 2s;
}

@media only screen and (max-width: 768px) {
  .capaa {
    margin-left: 20px;
    margin-top: 20px;
  }
}

@media only screen and (max-width: 480px) {
  .capaa {
    margin-left: 20px;
  }
}

.images-alignment {
  display: flex;
  flex-direction: row;
  margin-top: 30px;
}

@media only screen and (max-width: 768px) {
  .images-alignment {
    display: flex;
    flex-direction: column;
  }
  .spaceee {
    margin-left: 0px;
    margin-top: 20px;
  }
}

.spaceee {
  margin-left: 30px;
}

@media only screen and (max-width: 480px) {
  .images-alignment {
    display: flex;
    flex-direction: column;
  }
  .spaceee {
    margin-left: 0px;
    margin-top: 20px;
  }
}

.last-heading {
  font-size: 20px;
  margin-top: 90px;
  font-weight: bold;
}

.imgg {
  height: auto;
  max-width: 100%;
}

.bottom-content {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
