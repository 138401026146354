.mainContainer {
  position: relative;
  top: 0px;
  width: 100%;
  height: 80vh;
  overflow-x: hidden;
}

.map-content {
  position: absolute;
  top: 0px;
  height: 100%;
  width: 100vw;
  z-index: 0;

  transition: width 2s;
  transition: margin-left 0.5s;
}
.popup-content {
  max-width: 30vw;
  background-color: white;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 10px;
  
}
.popup-content p {
 margin:2;
}

.popup-content strong {
  font-weight: bold;
}


