


.bg-contactUs {
  background-image: url("https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/contactus_bg.png");
  background-size: cover;
  background-position: center;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  
}
  

.image-containerr {
  display: flex;
  justify-content: flex-start;
 
}

.contact-info {

  color: #FCB22F;
  
}

.heading-text {
  font-size: 20px;
 
  
  
}

.contact-email {
  font-size: 40px;
  font-weight: bold;
  color: #FCB22F;
  
  
  
}

.email-address {
  color: #FCB22F;
}

.email-address1 {
  color: #FCB22F;
 
}

@media (max-width: 767px) {
  .bg-contactUs {
    height: 100vh;
  }

  .image-container {
    margin-top: 10px;
  }

  .contact-info {
    margin-left: 20px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .bg-contactUs {
    height: 60vh;
  }
}


.white-text{
  color:#fff;
}

.scroll-text {
  margin-top:80rem;
  font-size: 15px;
  transform: rotate(-90deg);
  color: #fff;

  text-decoration: none;
}

.banner-text8 {
  color: #fff;
  margin-top: 360px;
  margin-left: 20px;
  
 
  
  
}

.banner-headingg {
  font-size: 20px;
  color:  #FCB22F;
}

.banner-para8 {
  font-size: 40px;
  font-weight: bold;
  /* display: flex;
  justify-content: flex-start;
  text-align: start;
  flex-direction: row; */
}


@media screen and (max-width: 768px) {
  .scroll-text {
    margin-top: 50px;
    font-size: 12px;
    transform: none;
    display: none;
    justify-content: center;
   
  }

  .banner-text8 {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  margin-left: 0px;
    
  }
  .bg-projects{
    display: flex;
   justify-content: center;
    align-items: center;
  }
  .banner-headingg {
    font-size: 15px;
    color:  #FCB22F;
    
  }
  .banner-para8 {
    font-size: 20px;
    font-weight: bold;  
  }
}


  
@media screen and (max-width: 768px) {
  .email-address1 {
    color: #FCB22F;
    margin-left: 0px;
    
  }
  .bg-contactUs {
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* text-align: start; */
  }
  .banner-text8 {
    color: #fff;
    margin-right: 0px;
    padding: 20px;
    
    
  }
  
}

@media screen and (max-width: 480px) {
  .email-address1 {
    color: #FCB22F;
    margin-left: 0px;
  }
  .bg-contactUs {
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 120vh;
    /* text-align: start; */
  }
  .banner-text8 {
    color: #fff;
    margin-right: 0px;
    text-align: center;
    
    
  }
   
}




.contact-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10px;
}

/* Default styles for larger screens */
.contact-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  text-align: center;
  gap: 20px;
  margin-bottom: 200px;
}

.contact-item {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 721px; 
 
  
  word-wrap: break-word; 
}
.contact-item1 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 500px; 
 
  
  word-wrap: break-word; 
}


@media screen and (max-width: 480px) {
  .contact-container {
    flex-direction: column;
    padding: 5px; 
  }
  .contact-item {
    width: 100%; 
    margin-left: 0; 
    word-wrap: break-word; 
  }
  .contact-item1 {
    margin-left: 0;
    width: 100%;
    word-wrap: break-word; 
  }
  .no-contact {
    display: none; 
  }
}


.contact-info-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.contact-inf {
  margin: 10px 0;
}

.vertical-line {
  height: 10px;
  width: 2px;
  background-color: #fff;
}