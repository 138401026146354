.bg-aboutus {
  background-image: url("https://d357a0xuzx4dll.cloudfront.net/gcrs-website/gcrs_images/aboutus_bg.png");
  background-size: cover;
  background-position: center;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

}

.scroll-text {
  margin-top: 190px;
  font-size: 15px;
  transform: rotate(-90deg);
  color: #fff;
  margin-right: 140px;
}

.banner-text2 {
  color: #fff;
}

.banner-heading {
  font-size: 20px;
  color: #fcb22f;
}

.banner-para2 {
  font-size: 40px;
  font-weight: bold;
  width: 70%;
}


@media screen and (max-width: 768px) {
  .scroll-text {
    margin-top: 50px;
    font-size: 12px;
    transform: none;
    display: none;
  }

  .banner-text2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;
  }
  .bg-aboutus {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .banner-heading {
    font-size: 15px;
    color: #fcb22f;
  }
  .banner-para2 {
    font-size: 20px;
    font-weight: bold;
    padding: 20px;
    width: 100%;
  }
}

.about-heading {
  font-size: 30px;
  font-weight: bold;
  margin-left: 150px;
  margin-top: 100px;
}

.first-para {
  margin-left: 150px;
}

@media screen and (max-width: 480px) {
  .bg-about {
    padding: 10px;
    background-position: center;
  }
  .banner-text2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* color: #fff; */
  }
  .bg-aboutus {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    background-size: cover;
    background-position: center;
  }
  .banner-para2 {
    font-size: 17px;
    font-weight: bold;
    width: 100%;
  }
}

.text-container {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: start;
  text-align: start;
}  
.items-container {
  display: flex;
  flex-direction: row;
}

.about1 {
  font-size: 23px;
  font-weight: bold;
  margin-left: 3.5rem;
  margin-right: 3.5rem;
  /* font-family: 'Times New Roman', Times, serif; */
  margin-top: 100px;
  width:70%
 
}
.about2 {
  margin-top: 50px;
  margin-left: 3.5rem;
  margin-right: 3.5rem;
  width:70%
}

@media only screen and (max-width: 768px) {
  .about1 {
    font-size: 18px;
    margin-left: 40px;
    margin-top: 20px;
    margin-right: 20px;
    width:90%
  }
  .about2 {
    margin-left: 40px;
    margin-top: 20px;
    margin-right: 20px;
    width:90%
  }
}

@media only screen and (max-width: 480px) {
  .about1 {
    margin-left: 20px;
    width:90%
  }
  .about2 {
    margin-left: 20px;
    margin-top: 20px;
    width:90%
  }
}
