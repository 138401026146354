.commaw {
  margin-left: 120px;
  margin-top: 100px;
  margin-bottom: 50px;
  transition-duration: 2s;
}

@media only screen and (max-width: 768px) {
  .commaw {
    margin-left: 20px;
    margin-top: 20px;
  }
}

@media only screen and (max-width: 480px) {
  .commaw {
    margin-left: 20px;
  }
}
