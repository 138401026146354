.image-container {
    display: flex;
    justify-content: center;
    margin-top: 20px; 
  }
  .components-container {
    display: flex;
    /* flex-direction: row; */
  }

  .bg-news {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 80px;
    margin-top: 140px;
  }
  
  .main-heading1{
    font-size: 28px;
    font-weight: bold;
    color: #bd0324;
    margin-top: 20px; 
    
  }
  
  .sub-heading1 {
    font-weight: bold;
    color: #000000;
    margin-top: 10px;
   width: 70%;
  }

  @media screen and (max-width: 480px) {
 

    .main-heading1{
     font-size: 23px;
      
    }
    
    .sub-heading1 {
     font-size: 19px;
     width: 100%;
    }
    .bg-news {
     
      padding: 20px;
     
    }
    
  
  }
  
  .image-con{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-top: 20px;
  }
  
  .image-con img {
    max-width: 100%;
    height: auto;
    margin: 10px;
  }