@media (max-width: 768px) {
  .randd-content.active {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .randd-content.active h1,
  .randd-content.active p {
    width: 100%;
  }
}

@media (min-width: 769px) {
  .randd-content.active {
    display: block;
    text-align: left;
  }
  .randd-content.active h1,
  .randd-content.active p {
    width: auto;
  }
}
.randD {
  margin-left: 120px;
  margin-top: 90px;
  margin-bottom: 30px;
  transition-duration: 2s;
}

@media only screen and (max-width: 768px) {
  .randD {
    margin-left: 20px;
    margin-top: 20px;
  }
}

@media only screen and (max-width: 480px) {
  .randD {
    margin-left: 20px;
  }
}

.r-d-styles {
  height: 120px;
}
