.map-area iframe {
  width: 100%;
  height: 500px;
  border: none;
  border: none !important;
  display: block;
}

.map-area.map-area-hight iframe {
  height: 448px;
}




.contact-info-content {
  display: flex;
  flex-direction: row;
}

.vertical-line {
  border-left: 1px solid #4d4d4d;
  height: 100%;
  margin: 0 10px;
  width: 3px;
  display: inline-block;
  min-height: 5em;
  color: #fff;
  background-color: #fff;
}

.contact-inf {
  flex: 1;
}
