/* OurJourney.css */

.bg-text {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    padding: 20px;
    padding-top: 140px;
  }
  
  .main-heading {
    font-size: 28px;
    font-weight: bold;
    color: #bd0324;
    margin-top: 20px; 
    margin-left: 70px;
   
  }
  
  .sub-heading {
    font-weight: bold;
    color: #000000;
    margin-top: 10px;
    margin-left: 90px;
    width: 70%;
  }
  
  .paragraph {
    color: #000000;
    font-size: 20px;
    margin-top: 20px;
    margin-left: 90px;
    width: 70%;
  }
  
  .image-container {
    text-align: center;
    margin-top: 30px;
  }
  
  .image-grid {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 20px;
  }
  
  .image-grid img {
    max-width: 100%;
    height: auto;
    margin: 10px;
  }
  
  @media screen and (max-width: 768px) {
    .main-heading {
      font-size: 24px;
    }
  
    .sub-heading {
      font-size: 18px;
      
    }
  
    .paragraph {
      font-size: 16px;
    }
  }
  
  @media screen and (max-width: 480px) {
    .main-heading {
      font-size: 16px;
      margin-left: 2px;
      
    }
  
    .sub-heading {
      font-size: 14px;
      margin-left: 10px;
      width: 100%;
    }
  
    .paragraph {
      font-size: 12px;
      margin-left: 10px;
      width: 100%;
    }
  
    .image-grid img {
      margin: 5px; 
    }
  }
  
  
  .journey-image {
    max-width: 60%;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  
  @media screen and (max-width: 768px) {
    .journey-image {
      max-width: 90%;
    }
  }
  
  @media screen and (max-width: 480px) {
    .journey-image {
      max-width: 70%;
    }
  }
  